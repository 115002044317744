function gtag_report_conversion(url) {
	var callback = function () {
	  if (typeof url != "undefined") {
		window.location = url;
	  }
	};
	gtag("event", "conversion", {
	  send_to: "AW-16740764936/san3CL3VsOcZEIiaz64-",
	  event_callback: callback,
	});
	return false;
  }

document.querySelectorAll(".whatsappFloating").forEach(
	(element) => {
		element.addEventListener("click", gtag_report_conversion)
	}
)